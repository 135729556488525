import { Container, Button, TextField, Typography, CircularProgress } from '@mui/material';
import { useContext } from 'react';
import styles from './login.module.scss';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { InternalUserContext } from '@src/context/internal-user-context';

function LoginComponent() {
    const location = useLocation();
    const navigate = useNavigate();

    const { validUserExist, credential, didFinishedLoading, setCredential, login } =
        useContext(InternalUserContext);

    const loginHandler = () => {
        login().then(() => {
            try {
                navigate(location.state.from.pathname, { state: location });
            } catch {
                navigate('/');
            }
        });
    };

    if (!validUserExist) {
        if (!didFinishedLoading) {
            return (
                <div className={styles.waitingContainer}>
                    <CircularProgress size="128px" />
                    <Typography variant="h5" component="p">
                        Authentication Loading...
                    </Typography>
                </div>
            );
        }
        return (
            <Container maxWidth="xl">
                <div className={styles.login_container}>
                    <form>
                        <TextField
                            required
                            id="outlined-required-n"
                            label="User Name"
                            name="username"
                            type="text"
                            autoComplete="username"
                            InputLabelProps={{ shrink: true, color: 'primary' }}
                            value={credential.n}
                            onChange={(e) => setCredential({ p: credential.p, n: e.target.value })}
                        />
                        <TextField
                            required
                            id="outlined-required-p"
                            label="Password"
                            type="password"
                            name="password"
                            autoComplete="current-password"
                            InputLabelProps={{ shrink: true, color: 'primary' }}
                            value={credential.p}
                            onChange={(e) => setCredential({ n: credential.n, p: e.target.value })}
                        />
                        <Button variant="outlined" onClick={loginHandler}>
                            Login
                        </Button>
                    </form>
                </div>
            </Container>
        );
    } else {
        return <Navigate to="/" />;
    }
}

export default LoginComponent;
