import { InternalUserContext } from '@src/context/internal-user-context';
import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface RequireAuthProps {
    children: React.ReactElement<any>;
}

function RequireAuth({ children }: RequireAuthProps) {
    const { validUserExist } = useContext(InternalUserContext);
    const location = useLocation();

    if (!validUserExist) {
        return <Navigate to="/login" state={{ from: location }} />;
    }

    return children;
}

export default RequireAuth;
