import useFirebaseStorage from '@src/hooks/useStorage';
import { Container, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useCallback } from 'react';
function Private() {
    const { albumList, selectedAlbumList, setSelectedAlbumList, httpImageList } =
        useFirebaseStorage();

    const validHttpImageList = !!httpImageList.length;

    const IMAGE = useCallback(() => {
        if (validHttpImageList) {
            return httpImageList.map((httpimage, index) => (
                <img key={index} width={'100%'} height={'auto'} src={httpimage} />
            ));
        }
    }, [httpImageList]);

    const Selector = useCallback(() => {
        return (
            <div style={{ width: '150px', padding: '16px 0', margin: 'auto' }}>
                <FormControl fullWidth>
                    <InputLabel id="album-selector">Album</InputLabel>
                    <Select
                        value={selectedAlbumList.name}
                        onChange={(e) => {
                            setSelectedAlbumList(
                                albumList.find((a) => a.name === e.target.value) || {
                                    name: '',
                                    path: ''
                                }
                            );
                            setTimeout(() => {
                                scrollTo({ left: 0, top: 0, behavior: 'smooth' });
                            }, 200);
                        }}
                        label="Album"
                        labelId="album-selector"
                    >
                        {[{ name: '', path: '' }, ...albumList].map((album, index) => {
                            return (
                                <MenuItem style={{ height: '40px' }} key={index} value={album.name}>
                                    {album.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
        );
    }, [albumList, selectedAlbumList]);

    return (
        <Container maxWidth="xl">
            <Selector />
            <div>
                <IMAGE />
            </div>
            {validHttpImageList && <Selector />}
        </Container>
    );
}

export default Private;
