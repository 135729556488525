import { signInWithEmailAndPassword, Auth } from 'firebase/auth';

export async function emailPasswordLogin(auth: Auth, email: string, password: string) {
	await signInWithEmailAndPassword(auth, email, password)
		.then((userCredential) => {
			const user = userCredential.user;
			return user;
		})
		.catch((error) => {
			const errorCode = error.code;
			const errorMessage = error.message;
			throw new Error(`Error when login, ${errorCode} :: ${errorMessage}`);
		});
}