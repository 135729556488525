import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MACHINESTATUS } from '@src/hooks/useDevice';

interface ConfirmationDialogProps {
    children: React.ReactNode;
    machineStatus: MACHINESTATUS;
    addedFunction: () => void;
}

export default function ConfirmationDialogWrpaper(props: ConfirmationDialogProps) {
    const { children, machineStatus, addedFunction } = props;
    const [open, setOpen] = React.useState(false);
    const [confirm, setConfirm] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (Y: boolean) => {
        Y ? setConfirm(true) : setConfirm(false);
        setOpen(false);
    };

    const keyword = machineStatus === 'running' ? 'power off' : 'boot';
    const titleTemplate = `Ready to ${keyword} your main device?`;
    const textTemplate = `Continue will ${keyword} your main device, please confirm your selection?`;

    React.useEffect(() => {
        if (confirm) addedFunction();
    }, [confirm]);

    React.useEffect(() => {
        return () => {
            setConfirm(false);
        };
    });

    return (
        <div>
            {React.cloneElement(children as React.ReactElement<any>, { onClick: handleClickOpen })}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{titleTemplate}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {textTemplate}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)}>Back</Button>
                    <Button onClick={() => handleClose(true)} autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
