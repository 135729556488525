import { isDev } from '@src/utilities';
import axios from '../axios';

const homeAPI = isDev ? 'http://localhost:7776/api' : '/api';

const boot_route = '/device/boot';
const status_route = '/device/status';
const poweroff_route = '/device/poweroff';

// const boot = async () =>
//     isDev
//         ? await new Promise((resolve) =>
//               setTimeout(() => {
//                   resolve('boot is triggered');
//               }, 1000)
//           )
//         : await axios.get(homeAPI + boot_route);

// const status = async () =>
//     isDev
//         ? await new Promise((resolve) =>
//               setTimeout(() => {
//                   resolve({ data: { data: false } });
//               }, 1000)
//           )
//         : await axios.get(homeAPI + status_route);
// const poweroff = async () =>
//     isDev
//         ? await new Promise((resolve) =>
//               setTimeout(() => {
//                   resolve('poweroff is triggered');
//               }, 1000)
//           )
//         : await axios.get(homeAPI + poweroff_route);

const boot = async (signal: AbortSignal | undefined) => await axios.get(homeAPI + boot_route, { signal });

const status = async (signal: AbortSignal | undefined) => await axios.get(homeAPI + status_route, { signal });

const poweroff = async (signal: AbortSignal | undefined) =>
    await axios.get(homeAPI + poweroff_route, { signal });

const deviceService = { boot, poweroff, status };

export default deviceService;
