import Typography from '@mui/material/Typography';
import { ALL_DATA_TYPE, QUERY_TYPE } from '@src/hooks/useDevice';
import ReactJson from 'react-json-view';

const typeOfDeviceLogs: QUERY_TYPE[] = ['status', 'boot', 'poweroff'];

function DeviceLogs({ data }: { data: ALL_DATA_TYPE }) {
    return (
        <div>
            {typeOfDeviceLogs.map((type, index) => (
                <div key={index} style={{ paddingBottom: '16px', borderBottom: '1px dotted #3b3b3b' }}>
                    <Typography
                        variant="subtitle1"
                        fontSize={'16px'}
                        fontWeight={600}
                        textTransform={'capitalize'}
                    >
                        {type}
                    </Typography>
                    <ReactJson
                        src={data[type]}
                        collapsed={1}
                        iconStyle="square"
                        name="data"
                        sortKeys
                    />
                </div>
            ))}
        </div>
    );
}

export default DeviceLogs;
