import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ALL_DATA_TYPE } from '@src/hooks/useDevice';
import DeviceLogs from './logs';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabContent(props: TabPanelProps) {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`device-log-panel-${index}`}
            aria-labelledby={`device-log-tab-${index}`}
            style={{ marginTop: '16px' }}
        >
            {children}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `device-log-tab-${index}`,
        'aria-controls': `device-log-panel-${index}`
    };
}

export default function DeviceLoggerPanel({
    allData,
    allError
}: {
    allData: ALL_DATA_TYPE;
    allError: ALL_DATA_TYPE;
}) {
    const [panelIndex, setPanelIndex] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setPanelIndex(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={panelIndex} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Data" {...a11yProps(0)} />
                    <Tab label="Error" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabContent value={panelIndex} index={0}>
                <DeviceLogs data={allData} />
            </TabContent>
            <TabContent value={panelIndex} index={1}>
                <DeviceLogs data={allError} />
            </TabContent>
        </Box>
    );
}
