import { useUser } from '@src/hooks';
import useAuthorizeRoute from '@src/hooks/useAuthorizeRoute';
import { createContext, useState, ReactElement, Dispatch, SetStateAction, useMemo } from 'react';

interface InternalUserContextInterface {
    authorizeRoute: string[];
    validAuthorizeRoute: Boolean;
    validUserExist: Boolean;
    didFinishedLoading: Boolean;
    credential: {
        n: string;
        p: string;
    };
    setCredential: Dispatch<SetStateAction<{ n: string; p: string }>>;
    login: () => Promise<void>;
    signout: () => void;
}

export const InternalUserContext = createContext({} as InternalUserContextInterface);

const InternalUserContextProvider = ({ children }: { children: ReactElement }) => {
    const [credential, setCredential] = useState({ n: '', p: '' });
    const { user, didFinishedLoading, login, firebaseSignout } = useUser({
        username: credential.n,
        password: credential.p
    });

    const validUserExist = useMemo(() => user === undefined ? false : user && !!user.uid.length, [user]);

    const { authorizeRoute, validAuthorizeRoute } = useAuthorizeRoute(user);
    function signout() {
        firebaseSignout();
        setCredential({ n: '', p: '' });
    }

    return (
        <InternalUserContext.Provider
            value={{
                authorizeRoute,
                validAuthorizeRoute,
                validUserExist,
                didFinishedLoading,
                credential,
                setCredential,
                login,
                signout
            }}
        >
            {children}
        </InternalUserContext.Provider>
    );
};

export default InternalUserContextProvider;
