import { InternalUserContext } from '@src/context/internal-user-context';
import { storage } from '@src/utilities/firebase';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { useContext, useEffect, useState } from 'react';

interface STORAGE_INTERFACE {
    name: string;
    path: string;
}

function useFirebaseStorage() {
    const { authorizeRoute, validAuthorizeRoute } = useContext(InternalUserContext);
    const shouldContinue = validAuthorizeRoute && !!authorizeRoute.find((r) => r === 'image');
    const listRef = ref(storage, '/private/');
    const [albumList, setAlbumList] = useState<STORAGE_INTERFACE[]>([]);
    const [selectedAlbumList, setSelectedAlbumList] = useState<STORAGE_INTERFACE>({
        name: '',
        path: ''
    });
    const [httpImageList, setHttpImageList] = useState<string[]>([]);

    useEffect(() => {
        if (shouldContinue && albumList.length === 0) {
            listAll(listRef).then((res) => {
                setAlbumList(() =>
                    res.prefixes.map((prefix) => ({ name: prefix.name, path: prefix.fullPath }))
                );
            });
        }
    }, [shouldContinue]);

    useEffect(() => {
        if (!!selectedAlbumList.path.length) {
            listAll(ref(storage, selectedAlbumList.path)).then((res) => {
                const promiseURLset = res.items.map((item) =>
                    getDownloadURL(ref(storage, item.fullPath))
                );
                Promise.all(promiseURLset).then((data) => {
                    setHttpImageList(data);
                });
            });
        }
    }, [selectedAlbumList]);

    return {
        albumList,
        selectedAlbumList,
        setSelectedAlbumList,
        httpImageList
    };
}

export default useFirebaseStorage;
