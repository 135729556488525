import { useContext } from 'react';
import { StyledEngineProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import ApplicationBar from '@components/app_bar/appbar';
import { Provider } from 'react-redux';
import store from './redux/store';
import { queryClient, RequireAuth } from './utilities';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import InternalUserContextProvider, {
    InternalUserContext
} from '@src/context/internal-user-context';
import { LoginComponent, HomePage, DeviceManagementComponent } from './pages';
import Private from './pages/image/image';

function Main() {
    const { authorizeRoute, validAuthorizeRoute } = useContext(InternalUserContext);

    const map = [<DeviceManagementComponent />, <Private />];

    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginComponent />} />
            {validAuthorizeRoute &&
                authorizeRoute.map((route, index) => (
                    <Route
                        key={index}
                        path={`/${route}`}
                        element={<RequireAuth>{map[index]}</RequireAuth>}
                    />
                ))}
        </Routes>
    );
}

function WrappedMain() {
    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <StyledEngineProvider injectFirst>
                    <InternalUserContextProvider>
                        <BrowserRouter>
                            <ApplicationBar>
                                <Main />
                            </ApplicationBar>
                        </BrowserRouter>
                    </InternalUserContextProvider>
                </StyledEngineProvider>
            </QueryClientProvider>
        </Provider>
    );
}

export default WrappedMain;
