import { emailPasswordLogin } from '@src/services';
import {
    getIdToken,
    onAuthStateChanged,
    getAuth,
    signOut as signOutFirebase,
    User
} from 'firebase/auth';
import React from 'react';
import fbapp from '@src/utilities/firebase';
import firebaseAxios from '@src/services/axios';

import { useState } from 'react';

interface CREDENTIAL {
    username: string;
    password: string;
}

export type firebaseUser = User | undefined;

function useUser(props: CREDENTIAL) {
    const { username, password } = props;
    const [didFinishedLoading, setDidFinishedLoading] = useState<Boolean>(false);
    const [user, setUser] = useState<firebaseUser>(undefined);
    const auth = getAuth(fbapp);

    function login() {
        setDidFinishedLoading(false);
        return emailPasswordLogin(auth, username, password);
    }

    function firebaseSignout() {
        signOutFirebase(auth)
            .then(() => {
                setUser(undefined);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    React.useEffect(() => {
        const unlisten = onAuthStateChanged(auth, (user) => {
            if (user) {
                getIdToken(user, true).then((idToken) => {
                    firebaseAxios.defaults.headers.common.authorization = idToken;
                    firebaseAxios.defaults.headers.common.uid = user.uid;
                });
                setUser(user);
            } else {
                setUser(undefined);
            }
            setTimeout(() => {
                setDidFinishedLoading(true);
            }, 500);
        });
        return () => {
            setDidFinishedLoading(false);
            unlisten();
        };
    }, []);

    return { user, didFinishedLoading, login, firebaseSignout };
}

export default useUser;
