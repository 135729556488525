import { LoadingButton } from '@mui/lab';
import ConfirmationDialogWrpaper from '@components/confirmation-dialog';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useDevice } from '@src/hooks';
import { CircularProgress, Container, Typography } from '@mui/material';
import styles from './device-management.module.scss';
import { useMemo, useCallback } from 'react';
import { MACHINESTATUS, queryKeyEnum } from '@src/hooks/useDevice';
import DeviceLoggerPanel from '@components/device_management/device-logger-panel';

function DeviceManagementComponent() {
    const { setQueryAndRefetch, machineStatus, isFetching, allData, allError } = useDevice();

    const actionButtonHandler = useCallback(
        (machineStatus: MACHINESTATUS) => {
            if (machineStatus === 'running') {
                return setQueryAndRefetch(queryKeyEnum.POWEROFF);
            } else {
                if (machineStatus === undefined) {
                    return;
                } else {
                    return setQueryAndRefetch(queryKeyEnum.BOOT);
                }
            }
        },
        [machineStatus]
    );

    const buttonLabel = useCallback((firstButton: boolean) => {
        if (firstButton) {
            if (isFetching) return 'Checking status...';
            return 'Check status'
        } else {
            switch (machineStatus) {
                case 'running':
                    return 'Power Off Your PC';
                case 'stopped':
                    return 'Boot your PC';
                default:
                    return 'Checking status...';
            }
        }

    }, [isFetching, machineStatus]);

    const PCPowerIcon = useMemo(() => {
        if (isFetching) return <CircularProgress size="16.67px" color="inherit" />;
        switch (machineStatus) {
            case 'running':
                return <PowerOffIcon />;
            case 'stopped':
                return <PowerIcon />;
            default:
                return <CircularProgress size="16.67px" color="inherit" />;
        }
    }, [isFetching, machineStatus]);

    return (
        <Container maxWidth="xl">
            <div className={styles.device_management_container}>
                <Typography variant="h5" component="p">
                    Main Device Power Management
                </Typography>
                <div className={styles.buttonGroupForWakeup}>
                    <LoadingButton
                        loading={isFetching}
                        loadingPosition="start"
                        startIcon={<RefreshIcon />}
                        variant="outlined"
                        onClick={() => setQueryAndRefetch(queryKeyEnum.STATUS)}
                    >
                         {buttonLabel(true)}
                    </LoadingButton>
                    <ConfirmationDialogWrpaper
                        addedFunction={() => actionButtonHandler(machineStatus)}
                        machineStatus={machineStatus}
                    >
                        <LoadingButton
                            loading={isFetching}
                            loadingPosition="start"
                            startIcon={PCPowerIcon}
                            variant="outlined"
                            disabled={isFetching || machineStatus === undefined}
                        >
                            {buttonLabel(false)}
                        </LoadingButton>
                    </ConfirmationDialogWrpaper>
                </div>
                <DeviceLoggerPanel allData={allData} allError={allError} />
            </div>
        </Container>
    );
}

export default DeviceManagementComponent;
