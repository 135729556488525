import { createSlice } from '@reduxjs/toolkit';

interface UserInterface {
    uid: string;
    didFinishedLoading: boolean;
}

const initialState: UserInterface = {
    uid: '',
    didFinishedLoading: false
};

export const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setUserAction: (state, action) => {
            state.uid = action.payload;
        },
        setDidFinishedLoading: (state, action) => {
            state.didFinishedLoading = action.payload;
        }
    }
});

export const { setUserAction, setDidFinishedLoading } = userSlice.actions;
export default userSlice.reducer;
