import './global.scss';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { createRoot } from 'react-dom/client';
import App from '@src/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import app from './utilities/firebase';

const analytics = getAnalytics(app);
logEvent(analytics, 'logging application access');

const root = createRoot(document.getElementById('root')!);

root.render(<App />);
