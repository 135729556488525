import {  Container } from '@mui/material';

function HomePage() {
    return (
        <Container maxWidth='xl'>
            no content
        </Container>
    );
}

export default HomePage;
