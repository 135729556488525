import { deviceService } from '@src/services';
import { useState, useEffect } from 'react';
import { QueryObserverResult, UseQueryResult, useQueries } from '@tanstack/react-query';

export type QUERY_TYPE = 'status' | 'boot' | 'poweroff';
export type MACHINESTATUS = 'running' | 'stopped' | undefined;
export type ALL_DATA_TYPE = Record<QUERY_TYPE, {}>

const queryKeyMap: QUERY_TYPE[] = ['status', 'boot', 'poweroff'];

export enum queryKeyEnum {
    STATUS = 0,
    BOOT = 1,
    POWEROFF = 2
}

interface useQueriesInterface extends Array<any> {
    queries: UseQueryResult[];
}

const useDeviceQuery = () => {
    const queries = useQueries<useQueriesInterface>({
        queries: [
            {
                queryKey: [queryKeyMap[queryKeyEnum.STATUS]],
                queryFn: ({ signal }) => deviceService.status(signal),
                cacheTime: 0,
                enabled: false
            },
            {
                queryKey: [queryKeyMap[queryKeyEnum.BOOT]],
                queryFn: ({ signal }) => deviceService.boot(signal),
                cacheTime: 0,
                enabled: false
            },
            {
                queryKey: [queryKeyMap[queryKeyEnum.POWEROFF]],
                queryFn: ({ signal }) => deviceService.poweroff(signal),
                cacheTime: 0,
                enabled: false
            }
        ]
    });

    const queriesResult = queries.map((query, index) => ({
        queryKey: queryKeyMap[index],
        refetch: query.refetch,
        data: query.data,
        error: query.error,
        isFetching: query.isFetching
    }));

    return queriesResult;
};

/**
 * @todo: maunal delay isfetching
 */
const useDevice = () => {
    const [allData, setAllData] = useState<ALL_DATA_TYPE>({ status: {}, boot: {}, poweroff: {} });
    const [allError, setAllError] = useState<ALL_DATA_TYPE>({ status: {}, boot: {}, poweroff: {} });
    const queriesResult = useDeviceQuery();

    const statusQuery = queriesResult[queryKeyEnum.STATUS];
    const bootQuery = queriesResult[queryKeyEnum.BOOT];
    const poweroffQuery = queriesResult[queryKeyEnum.POWEROFF];

    const isFetching = statusQuery.isFetching || bootQuery.isFetching || poweroffQuery.isFetching;

    const [machineStatus, setMachineStatus] = useState<MACHINESTATUS>();

    const status = allData.status;

    const responseMapHandler = (resposnse: QueryObserverResult, whatQueryIndex: number) => {
        if (resposnse.data) setAllData({ ...allData, [queryKeyMap[whatQueryIndex]]: resposnse });
        if (resposnse.error) setAllError({ ...allData, [queryKeyMap[whatQueryIndex]]: resposnse });
    };

    const setQueryAndRefetch = (whatQueryIndex: number) => {
        queriesResult[whatQueryIndex]
            .refetch()
            .then((response) => responseMapHandler(response, whatQueryIndex));
    };

    const mapMachineStatus = (status: any) => {
        if (
            status &&
            status.data &&
            status.data.data &&
            typeof status.data.data.data === 'boolean'
        ) {
            if (status.data.data.data === true) {
                setMachineStatus('running');
            } else {
                setMachineStatus('stopped');
            }
        } else {
            setMachineStatus(undefined);
        }
    };

    useEffect(() => {
        mapMachineStatus(status);
    }, [status]);

    useEffect(() => {
        statusQuery.refetch().then((response) => responseMapHandler(response, queryKeyEnum.STATUS));
    }, []);

    return {
        machineStatus,
        setQueryAndRefetch,
        isFetching,
        allData,
        allError
    };
};

export default useDevice;
