import { useEffect, useState, useMemo } from 'react';
import { database } from '@src/utilities/firebase';
import { ref, onValue } from 'firebase/database';
import { Unsubscribe } from 'firebase/auth';
import { firebaseUser } from './useUser';

function useAuthorizeRoute(user: firebaseUser) {
    const [authorizeRoute, setAuthorizeRoute] = useState<string[]>([]);

    const validAuthorizeRoute = useMemo(() => !!authorizeRoute.length, [authorizeRoute]);

    useEffect(() => {
        let unlisten: Unsubscribe;
        if (user) {
            const authorizeRootDBendpoint = ref(
                database,
                'authorize-root/' + user.uid + '/allowed-route'
            );
            unlisten = onValue(authorizeRootDBendpoint, (snapshot) => {
                const data = snapshot.val();
                setAuthorizeRoute(data);
            });
        }
        return () => {
            if (unlisten) unlisten();
        };
    }, [user]);

    return { authorizeRoute, validAuthorizeRoute };
}

export default useAuthorizeRoute;
