import React, { useContext, useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import {
    AppBar,
    Toolbar,
    Container,
    Button,
    ButtonBase,
    Typography,
    CircularProgress
} from '@mui/material';
import styles from './appbar.module.scss';
import { useNavigate } from 'react-router-dom';
import { InternalUserContext } from '@src/context/internal-user-context';
import czIcon from '@public/apple-touch-icon.png';

export interface ApplicationBarProps {
    children: React.ReactElement<any>;
}

export default function ApplicationBar({ children }: ApplicationBarProps) {
    const navigate = useNavigate();
    const { validUserExist, authorizeRoute, validAuthorizeRoute, signout } =
        useContext(InternalUserContext);

    const AppBarButtonGroup = useCallback(() => {
        if (validUserExist) {
            if (validAuthorizeRoute) {
                return authorizeRoute.map((route, index) => {
                    return (
                        <ButtonBase
                            key={index}
                            className={styles.textButton}
                            onClick={() => navigate(`/${route}`)}
                        >
                            <Typography variant="body1" component="p">
                                {route}
                            </Typography>
                        </ButtonBase>
                    );
                });
            } else {
                return (
                    <div className={styles.loadingButton}>
                        <CircularProgress size="24px" />
                    </div>
                );
            }
        } else {
            return <></>;
        }
    }, [validUserExist, validAuthorizeRoute]);

    return (
        <>
            <AppBar
                color="transparent"
                position="static"
                sx={{ marginBottom: '8px' }}
                elevation={1}
            >
                <Container maxWidth="xl">
                    <Toolbar disableGutters className={styles.toolBar}>
                        <div className={styles.left_container}>
                            <IconButton onClick={() => navigate('/')}>
                                <img src={czIcon} alt="henry" />
                            </IconButton>
                        </div>

                        <div className={styles.right_container}>
                            <div className={styles.navigation_button_groups}>
                                <AppBarButtonGroup />
                            </div>
                            <Button
                                className={styles.log_in_out_button}
                                color="inherit"
                                onClick={validUserExist ? signout : () => navigate('/login')}
                            >
                                {validUserExist ? 'sign out' : 'login'}
                            </Button>
                        </div>
                    </Toolbar>
                </Container>
            </AppBar>
            {children}
        </>
    );
}
