import axios from 'axios';

const firebaseAxios = axios.create();

// firebaseAxios.interceptors.response.use(
//     (response) => {
//         return response;
//     },
//     (error) => {
//         if (error.code === 'ERR_CANCELED') {
//             // aborted in useEffect cleanup
//             return Promise.resolve({ status: 499 });
//         }
//         return Promise.reject((error.response && error.response.data) || 'Error');
//     }
// );

export default firebaseAxios;
