import { configureStore } from '@reduxjs/toolkit';
import { isDev } from '@src/utilities';
import userSlice from './slices/user';

const middleWare = [];
if (isDev) {
    const { createLogger } = require('redux-logger');
    middleWare.push(createLogger());
}

const store = configureStore({
    reducer: {
        user: userSlice,
    },
    middleware: middleWare,
    devTools: isDev
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
